<template>
  <div class="expande-horizontal pa-3 wrap centraliza" style="height: 36vh;">
    <img style="width: 100px;" src="img/empty.gif" alt="empty" />
    <span class="px-3 fonte-italica fonte-subtitulo grey--text text-center">
      Não encontramos nenhum registro, clique no botão abaixo e cadastre um!
    </span>
  </div>
</template>

<script>
export default {
  props: {
    iniciarCadastro: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {};
  }
};
</script>
