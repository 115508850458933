<template>
  <div class="expande-horizontal pa-3 wrap centraliza" style="height: 36vh;">
    <!-- <img style="width: 100px;" src="img/empty.gif" alt="empty" /> -->
    <span style="margin-top: -130px" class="px-9 fonte-subtitulo grey--text text-center">
      Ninguem assinou pelo seu link ainda, quando acontecer, aparecerá aqui!
    </span>
  </div>
</template>

<script>
export default {
  props: {
    iniciarCadastro: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {};
  }
};
</script>
