<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="27"
              :color="$theme.primary"
              class="mr-2"
              icon
            >
              <v-icon color="#f7f7f7">mdi-close</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="">
                {{
                  get_seller.new
                    ? "Novo seller"
                    : get_seller.nome
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- <v-list-item-action v-if="get_seller._id">
              <v-btn
                color="red"
                @click="excluir_seller_dialog(get_seller)"
                outlined
                small
                >Excluir</v-btn
              >
            </v-list-item-action> -->
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <div style="min-height: 84vh; align-items: flex-start;" class="windows-style-content expande-horizontal wrap">
      <!-- <v-tabs
        class="fonte"
        v-model="tab"
        show-arrows
        background-color="transparent"
      >
        <v-tab class="font-weight-bold">Informações</v-tab>
      </v-tabs> -->
      <v-flex xs12>
        <v-divider></v-divider>
      </v-flex>

      <div
        v-show="tab === 0"
        class="expande-horizontal wrap py-6 pb-0"
        style="min-height: 340px;"
      >
        <v-flex xs12>
          <v-form ref="form">
            <!-- <ModalSendArchive /> -->
            <!-- <v-flex class="px-3 pb-6" xs12 md4>
              <v-switch
                v-model="get_seller.finance_control"
                dense
                flat
                clearable
                :color="$theme.primary"
                label="Habilitar controle financeiro"
              ></v-switch>
            </v-flex> -->
            <div class="expande-horizontal wrap">
              <v-flex class="px-3" xs12 md4>
                <v-select
                  v-model="get_seller.permissao"
                  dense
                  flat
                  item-value="id"
                  item-text="nome"
                  :items="get_permissions.docs"
                  :color="$theme.primary"
                  label="Tipo de Usuário"
                ></v-select>
              </v-flex>
            </div>
            <div class="expande-horizontal wrap">
              <v-flex class="px-3" xs12 md4>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  Nome
                </span>
                <v-text-field
                  v-model="get_seller.nome"
                  dense
                  solo
                  flat
                  outlined
                  clearable
                  :color="$theme.primary"
                  :rules="[v => !!v || 'Preencha este campo']"
                  label="ex: Roberto"
                ></v-text-field>
              </v-flex>

              <v-flex class="px-3" xs12 md4>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  Email
                </span>
                <v-text-field
                  v-model="get_seller.email"
                  dense
                  solo
                  flat
                  outlined
                  clearable
                  :color="$theme.primary"
                  label="ex: email@gmail.com"
                ></v-text-field>
              </v-flex>
            </div>

            <div class="expande-horizontal">
              <v-flex class="px-3" xs12 md4>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  CPF
                </span>
                <v-text-field
                  v-model="get_seller.cpf"
                  dense
                  solo
                  flat
                  outlined
                  v-mask="['###.###.###-##']"
                  clearable
                  :color="$theme.primary"
                  label="ex: 000.000.000-00"
                ></v-text-field>
              </v-flex>
              <v-flex v-if="get_seller.permissao" class="px-3" xs12 md4>
                <span
                  class="py-3 fonte font-weight-bold"
                  :style="`font-size: 12px;color: ${$theme.secondary}`"
                >
                  Senha
                </span>
                <v-text-field
                  v-model="get_seller.senha"
                  dense
                  solo
                  flat
                  type="password"
                  outlined
                  clearable
                  hint="A senha será atualizada para essa"
                  :color="$theme.primary"
                  label="******"
                ></v-text-field>
              </v-flex>
            </div>
          </v-form>
        </v-flex>
      </div>
    </div>

    <div class="windows-style-bottom expande-horizontal pa-3">
      <v-btn dark @click="valida_form" tile :color="$theme.primary">
        <span class="fonte"> Salvar </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      tab: 0,
      transactions: [],
    };
  },
  computed: {
    ...mapGetters([
      "get_seller",
      "get_permissions",
      "get_permissions_filters",
      "get_modal_view_seller",
      "getLoggedUser"
    ]),
  },
  methods: {
    ...mapActions([
      "criar_seller",
      "list_permissions",
      "atualizar_seller",
      "fecha_modal_view_seller",
      "abre_modal_view_transacao"
    ]),
    backToList() {
      this.$store.commit("set_seller", {});
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_seller._id
          ? this.atualizar_seller()
          : this.criar_seller();
      }
    },
  },
  created() {
    this.list_permissions_filters.all = true;
    this.list_permissions();
  }
};
</script>

<style>
.item-transac {
  background: #f0f0f2;
  border-radius: 6px;
  margin-bottom: 6px;
}
</style>